/* You can add global styles to this file, and also import other style files */

html, body { height: 100%;
 }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }




.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 1s linear infinite;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #555;
  border-radius: 50%;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
